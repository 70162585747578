body {
    margin: 0;
}
h1,
h2,
p {
    font-family: sans-serif;
    text-align: center;
}

.App {
    height: 100vh;
    width: 100%;
    background-color: #e0f2f7;
}

.text-collapsible {
    margin-right: 1em;
}

#recurse-map {
    height: 80vh;
}

html {
    font-size: calc(1em + 1vw);
}

.navbar {
    background-color: #e0f2f7;
}

ul.navbar-nav {
    text-align: right;
}

.navbar-brand img {
    margin-right: 0.5em;
    width: 40px;
    height: 40px;
}

.navbar {
    font-size: calc(16px + 0.5vw);
}

.navbar-brand {
    font-size: calc(20px + 1vw);
    margin-right: 0%;
}

@media screen and (max-width: 500px){
    .navbar {
        padding: 0.5rem 0.4rem;
    }
    .navbar-brand {
        margin-right: 0px;
        font-size: 0px;
    }

    .github-link {
        display: none;
    }
}

.navbar-nav > li > a,
.navbar-brand {
    padding-top: 0.1125rem;
    padding-bottom: 0.1125rem;
}

.footer {
    bottom: 0;
    position: absolute;
    width: 100%;
    background-color: #f5f5f5;
    text-align: center;
    font-size: 14px;
    z-index: 999;
}

.footer-container a img {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.footer-container a {
    color: black;
}
