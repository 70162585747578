body {
    font-family: Helvetica, sans-serif;
}

#reset-button {
    font-size: 14px;
}

#search-spinner {
    width: 1em;
    height: 1em;
    margin: 0.5em;
}

.react-autosuggest__input {
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 55px;
    left: 15px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 100;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__suggestion-match {
    color: red;
    font-weight: bold;
}

@media screen and (max-width: 500px){
    #reset-button, .react-autosuggest__input {
        font-size: 10px;
    }
  }