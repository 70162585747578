.leaflet-container {
    height: 100%;
    width: 98%;
    margin: 0 auto;
    z-index: 10;
    top: 0px;
    left: 0px;
}

.circle-icon {
    background: #5aba47; /* color of the circle, RC logo green */
    border-radius: 50%; /* make the div a circular shape */
    box-shadow: 4px 4px 3px grey; /* see http://www.w3schools.com/css/css3_shadows.asp */
    -moz-box-shadow: 4px 4px 3px grey;
    -webkit-box-shadow: 4px 4px 3px grey;
    opacity: 0.85;
    color: #ffffff;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
}

.circle-cluster {
    background-color: rgba(169, 169, 169, 0.6);
    border-radius: 50%;
}

.circle-cluster div {
    background-color: rgba(128, 128, 128, 0.6);
    border-radius: 50%;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
}

.circle-icon-sm div,
.circle-cluster-sm div,
.circle-icon-md div,
.circle-cluster-md div,
.circle-icon-lg div,
.circle-cluster-lg div {
    font-size: 12px;
}

.circle-icon-xl div,
.circle-cluster-xl div,
.circle-icon-xxl div,
.circle-cluster-xxl div {
    font-size: 14px;
}

#map-spinner-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 3em;
}

#map-spinner {
    width: 10em;
    height: 10em;
}
