.leaflet-popup-content-wrapper {
  width: 100%;
  max-height: 60vh;
}

.leaflet-popup-content {
  flex: auto;
  max-width: 30vw;
  width: 400px;
}

.location-popup .location-name {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.location-popup .location-stats {
  font-style: italic;
  text-align: center;
}

.leaflet-popup-content {
  max-width: 90%;
  max-height: 60%;
  font-size: 16px;
  margin: 1.5em;
}

.leaflet-popup-content p {
  margin-top: 0rem;
  margin-bottom: 0.3rem;
}

.leaflet-popup-content ul {
  padding-inline-start: 1.5em;
}

.location-stats {
  font-style: italic;
  display: block;
  text-align: center;
}

.person-link {
  color: black;
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
}

.person-info {
  max-width: 65%;
}

.profile-photo {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 0.5rem;
  overflow: hidden;
  border: 1px solid lightgray;
  background: white;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.03);
}

.profile-photo img {
  transform: scale(0.6) translate(-40%, -40%);
}

.navbar .profile-photo {
    height: 40px;
    width: 40px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.03);
    margin-left: auto;
    margin-right: 0px;
}

.navbar .profile-photo img {
    transform: scale(0.3) translate(-120%, -120%);
  }